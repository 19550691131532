import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import Title from "../../components/commons/page/title/Index"
import Card from "../../components/commons/card/member/index"
// import Pagination from "../../components/commons/pagination/index"
import HorizontalAds from "../../components/commons/ads/horizontal-ads/Index"
import { withTrans } from "../../i18n/withTrans"
import { inRangeDate } from "../../utils/date"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Pagination from "@material-ui/lab/Pagination"

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))

const Index = ({ t, data, i18n }) => {
  const language = i18n.language
  const [searchTextTemp, setSearchTextTemp] = useState("")
  const [searchText, setSearchText] = useState("")
  const [region, setRegion] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [dataAnggota, setData] = useState(null)
  const [dataRegion, setDataRegion] = useState(null)
  const [lengthData, setLengthData] = useState(0)
  const [isDisabled, setIsDisabled] = useState(false)
  const classes = useStyles()
  const pageSize = 8
  const pagesCount = Math.ceil(lengthData / pageSize)

  const dataIklan = data?.allWpIklanGks?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )
  const dataIklanSilver = dataIklan?.filter(
    o => o.iklan.slotIklan === "Silver Ad Space (1060 x 150 px)"
  )

  const handlePageClick = (e, i) => {
    setCurrentPage(i)
  }

  const handlePreviousClick = (e, currentPage) => {
    setCurrentPage(currentPage - 1)
  }

  const handleNextClick = (e, currentPage) => {
    setCurrentPage(currentPage + 1)
  }

  const onSearch = e => {
    e.preventDefault()
    setSearchText(searchTextTemp)
  }

  useEffect(() => {
    getMember()
    getRegion()
  }, [])

  useEffect(() => {
    getMember()
  }, [searchText, region, currentPage])

  const getMember = () => {
    setIsDisabled(true)
    fetch(
      `${process.env.GATSBY_API_URL}/member?length=8&page=${currentPage}&region_id=${region}&search_text=${searchText}`
    )
      .then(response => {
        setIsDisabled(false)
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setData(result.data)
        setLengthData(result.recordsTotal)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getRegion = () => {
    fetch(`${process.env.GATSBY_API_URL}/region?draw=1&length=99999&page=1`)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Error")
        }
      })
      .then(result => {
        setDataRegion(result.data)
      })
      .catch(error => {
        console.log(error)
      })
  }
  return (
    <Layout>
      <Container className="page-container member-container">
        {/* <Title
          style={{ margin: "0 0 20px" }}
          label={t("header.activeMember")}
        /> */}
        {/* <Form> */}
        <Row className="justify-content-between align-items-center">
          <Col md={6} className="page-title m-0">
            <span>{t("header.activeMember")}</span>
          </Col>
          <Col md={6} xs={12}>
            <Row className="justify-content-between">
              <Col md={6} xs={12} className="d-flex justify-content-start mb-3">
                <Form.Control
                  onChange={e => setSearchTextTemp(e.target.value)}
                  placeholder={t("activeMember.searchMemberName")}
                />
                <Button
                  type="submit"
                  onClick={e => onSearch(e)}
                  disabled={isDisabled}
                >
                  {/* {t("search")} */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </Button>
              </Col>
              <Col
                md={6}
                xs={12}
                className="d-flex member-select justify-content-end mb-3"
              >
                <select
                  id="filter-anggota"
                  name="filter-anggota"
                  className="form-control"
                  onChange={e => setRegion(e.target.value)}
                >
                  <option value="" disabled selected>
                    {t("activeMember.region")}
                  </option>
                  {dataRegion && dataRegion.length && (
                    <option value="">{t("activeMember.allRegion")}</option>
                  )}
                  {dataRegion && dataRegion.length > 0 ? (
                    dataRegion.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      {t("noChoice")}
                    </option>
                  )}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </Form> */}
        <Row className="py-4 px-3">
          <Col md={12} style={{ padding: 0, marginBottom: 20 }}>
            <Row className="justify-content-around">
              {dataAnggota && dataAnggota.length > 0 ? (
                dataAnggota.map((item, i) => (
                  <Col md={3} style={{ padding: 0 }} key={i}>
                    <Card
                      name={item.name}
                      image={
                        item.member_profile_data.logo_filename
                          ? `${process.env.GATSBY_API_URL}/file/logo/${item.member_profile_data.logo_filename}`
                          : null
                      }
                      region={item.region_name}
                      id={item.id}
                    />
                  </Col>
                ))
              ) : (
                <div>{t("noData")}</div>
              )}
            </Row>
            {/* <div className="d-flex justify-content-center mt-3">
              <Pagination
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageClick={handlePageClick}
                handlePreviousClick={handlePreviousClick}
                handleNextClick={handleNextClick}
              />
            </div> */}
            <div
              className={`${classes.root} d-flex justify-content-center mt-4`}
            >
              <Pagination
                count={pagesCount}
                page={currentPage}
                onChange={handlePageClick}
                shape="rounded"
                color="primary"
              />
            </div>
          </Col>
          <Col md={12}>
            <div className="d-flex justify-content-center">
              {dataIklanSilver?.length > 0 &&
              inRangeDate(
                dataIklanSilver[0].iklan.tanggalJangkaIklanAwal,
                dataIklanSilver[0].iklan.tanggalJangkaIklanAkhir
              ) &&
              dataIklanSilver[0].iklan.uploadGambarIklanSilverAdSpace ? (
                <HorizontalAds
                  width={1060}
                  height={150}
                  title="SILVER AD SPACE"
                  img_url={
                    dataIklanSilver[0].iklan.uploadGambarIklanSilverAdSpace
                      .sourceUrl
                  }
                />
              ) : (
                <HorizontalAds
                  width={1060}
                  height={150}
                  title="SILVER AD SPACE"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpIklanGks(sort: { fields: date, order: DESC }) {
      nodes {
        title
        iklan {
          slotIklan
          deskripsiIklan
          letakIklan {
            bronzeAdSpace
            goldA
            premium
            silverAdSpace
            goldB
          }
          uploadGambarIklanBronzeAdSpace {
            sourceUrl
          }
          uploadGambarIklanGoldA {
            sourceUrl
          }
          uploadGambarIklanGoldB {
            sourceUrl
          }
          uploadGambarIklanPremium {
            sourceUrl
          }
          uploadGambarIklanSilverAdSpace {
            sourceUrl
          }
          tanggalJangkaIklanAkhir
          tanggalJangkaIklanAwal
        }
        date
        language {
          code
        }
      }
    }
  }
`

export default withTrans(Index)
